import { useRef, useEffect } from 'react';

const useAccessibilityFocusFirstElement = () => {
  const firstNavLinkRef = useRef();

  useEffect(() => {
    document.querySelector(`.accessibility-mode-on`) &&
      firstNavLinkRef.current?.focus();
  }, []);

  return firstNavLinkRef;
};

export default useAccessibilityFocusFirstElement;

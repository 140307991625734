import _ from 'lodash';
import { memo, Fragment, useMemo, useEffect, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import { useAccessibilityFocusFirstElement } from 'hooks';
import { notificationState } from 'states/notification';

import { displayStringWithLineReturn } from './Helpers';

const NotificationBase = ({ location }) => {
  const { t } = useTranslation();

  const canClosePathNameChangeRef = useRef(true);

  const notification = useRecoilValue(notificationState);
  const resetNotification = useResetRecoilState(notificationState);

  const closeButtonRef = useAccessibilityFocusFirstElement();

  const textIsArray = useMemo(
    () => Array.isArray(notification?.text),
    [notification]
  );

  const displayMessage = useCallback(
    (msg, index = 0) => {
      if (typeof msg === 'object')
        return (
          <div className={msg?.class || ''} key={index}>
            {displayStringWithLineReturn(t(msg?.text, msg?.variable))}
          </div>
        );
      return <div key={index}>{displayStringWithLineReturn(t(msg))}</div>;
    },
    [t]
  );

  useEffect(() => {
    if (typeof notification !== 'object') return;

    let time = 5000;
    const words = textIsArray
      ? Math.max(
          ..._.map(
            notification?.text,
            (msg) => t(msg?.text || msg, msg?.variable).length
          )
        )
      : t(
          notification?.text?.text || notification?.text,
          notification?.text?.variable
        ).length || 0;

    if (words > (time / 1000) * 4) {
      time = (words / 4) * 1000;
    }

    if (textIsArray) {
      time = (notification?.text.length || 1) * time;
    }

    closeButtonRef?.current?.focus();

    const timer = setTimeout(() => {
      resetNotification();
    }, time);
    return () => clearTimeout(timer);
  }, [notification]); // eslint-disable-line react-hooks/exhaustive-deps

  // Prevent that notification was reset when path change the first time
  useEffect(() => {
    canClosePathNameChangeRef.current = !notification;
    setTimeout(() => {
      canClosePathNameChangeRef.current = true;
    }, 100);
    // eslint-disable-next-line no-use-before-define
  }, [notification]);

  useEffect(
    () => {
      notification && canClosePathNameChangeRef.current && resetNotification();
    },
    [location?.pathname] // eslint-disable-line react-hooks/exhaustive-deps
  );

  if (typeof notification !== 'object') return <Fragment />;

  return (
    <div
      className={`notification-header notification--open notification-sticky app-notification ${
        notification.class || ''
      }`}
    >
      <div className="notification__content">
        <div className="notification__body" role="alert">
          {textIsArray
            ? _.map(notification.text, (msg, index) =>
                displayMessage(msg, index)
              )
            : displayMessage(notification.text)}
        </div>

        <button
          type="button"
          className="notification__close notification-cancel"
          aria-label={t('Close')}
          onClick={() => resetNotification()}
          ref={closeButtonRef}
        >
          <i className="icon icon-022-close" aria-hidden="true" />
        </button>
      </div>
    </div>
  );
};

const Notification = () => {
  // Remove the notification
  const location = useLocation();
  return <NotificationBase location={location} />;
};

export default memo(Notification);
export { NotificationBase };

import gql from 'graphql-tag';

export const CHECK_PERMISSION = gql`
  query GetCheckPermissions($accessReportId: UUID!) {
    result: checkPermissions(accessReportId: $accessReportId) {
      accessReportId
      remainingAttempts
      authenticationMethod
    }
  }
`;

export const SEND_TOKEN = gql`
  query SendToken($accessReportId: UUID!) {
    result: sendToken(accessReportId: $accessReportId)
  }
`;

export const CHECK_TWO_FACTOR_AUTH = gql`
  query CheckTwoFactorAuth($accessReportId: UUID!, $token: String!) {
    result: checkTwoFactorAuth(accessReportId: $accessReportId, token: $token) {
      sessionToken
      statusType
    }
  }
`;

export const GET_LIST_OF_FILES = gql`
  query GetFiles($accessReportId: UUID!, $sessionToken: String!) {
    result: files(
      accessReportId: $accessReportId
      sessionToken: $sessionToken
    ) {
      id
      name
      disabledDownload
    }
  }
`;

export const DOWNLOAD_FILES = gql`
  query GetDownloadFiles(
    $accessReportId: UUID!
    $fileIds: [UUID]!
    $sessionToken: String!
  ) {
    result: downloadFiles(
      accessReportId: $accessReportId
      fileIds: $fileIds
      sessionToken: $sessionToken
    ) {
      files {
        id
        name
        binaryFile
      }
      failedFileIds
    }
  }
`;

import Moment from 'moment';
import { useEffect, useState, memo } from 'react';
import { useResetRecoilState, useRecoilValue } from 'recoil';

import { TIME_BEFORE_RESEND_TOKEN } from 'constants/app';
import { delayButtonState } from 'states/app';
import LoadingSpinner from 'utils/LoadingSpinner';

const formatDiff = (diffTime) => {
  const duration = Moment.duration(diffTime, 'seconds');
  const secondsFormatted = `${
    duration.seconds() >= 10 ? '' : '0'
  }${duration.seconds()}`;

  return duration.minutes() > 0
    ? `${duration.minutes()}:${secondsFormatted}`
    : secondsFormatted;
};

const DelayButtonComponent = ({
  loading,
  idButton,
  label: labelBase,
  ...props
}) => {
  const [label, setLabel] = useState(labelBase);
  const [disabled, setDisabled] = useState(false);
  const delayButton = useRecoilValue(delayButtonState(idButton));
  const resetDelayButton = useResetRecoilState(delayButtonState(idButton));

  useEffect(() => {
    let reloadTimeButton;

    if (delayButton?.moment) {
      const datetimeLastClick = Moment(delayButton.moment).add(
        TIME_BEFORE_RESEND_TOKEN,
        'm'
      );
      let diffTime = datetimeLastClick.diff(Moment(), 'seconds');
      if (diffTime > 0) {
        setDisabled(true);
        setLabel(formatDiff(diffTime));
        reloadTimeButton = setInterval(() => {
          diffTime = datetimeLastClick.diff(Moment(), 'seconds');
          if (diffTime > 0) {
            setLabel(formatDiff(diffTime));
          } else {
            setDisabled(false);
            setLabel(labelBase);
            resetDelayButton();
          }
        }, 1000);
      }
    }
    return () => reloadTimeButton && clearInterval(reloadTimeButton);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delayButton]);

  return (
    <sdx-button-group layout="fill">
      {loading ? (
        <LoadingSpinner size="small" class="loading-button" />
      ) : (
        <sdx-button
          label={label}
          sr-hint={labelBase}
          disabled={disabled}
          {...props}
        >
          <LoadingSpinner size="small" />
        </sdx-button>
      )}
    </sdx-button-group>
  );
};

export default memo(DelayButtonComponent);

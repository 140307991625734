import { memo } from 'react';

import { language } from 'i18n';

const Footer = () => {
  const sharedHeader =
    window.location.host.includes('localhost') ||
    window.location.host.includes('filebox') ||
    window.location.host.includes('ba2-dev');

  return (
    <footer>
      {sharedHeader ? (
        <div className="footer footer--compact">
          <div className="container">
            <div className="logo">
              <span
                className="logo-lifeform"
                aria-label="Swisscom Logo"
                role="img"
              />
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="sdx-container">
            <ebc-footer />
          </div>

          <b2b-shared-footer lang={language()} />
        </>
      )}
    </footer>
  );
};

export default memo(Footer);

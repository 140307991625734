import { memo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const PageRedirectToDefault = () => {
  const navigate = useNavigate();

  useEffect(() => {
    switch (true) {
      default:
        navigate('/pageNotFound');
        break;
    }
  });

  return false;
};

export default memo(PageRedirectToDefault);

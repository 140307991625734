import { RecoilRoot } from 'recoil';

import Footer from 'layout/Footer';
import Header from 'layout/Header';
import AccessibilityMode from 'utils/AccessibilityMode';
import ErrorBoundaries from 'utils/ErrorBoundaries';
import { isUsingFirefox } from 'utils/Helpers';

import App from './App';
import DebugObserver from './DebugObserver';

const Main = () => {
  return (
    <div id="main">
      <ErrorBoundaries>
        <RecoilRoot>
          <AccessibilityMode />
          <Header />
          <App />
          <Footer />
          {process.env.NODE_ENV === 'development' && !isUsingFirefox() && (
            <DebugObserver />
          )}
        </RecoilRoot>
      </ErrorBoundaries>
    </div>
  );
};

export default Main;

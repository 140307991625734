import PropTypes from 'prop-types';
import { memo } from 'react';
import ContentLoader from 'react-content-loader';

const TextLoader = ({ withButton = false }) => {
  return (
    <ContentLoader width="100%" viewBox={`0 0 800 270`}>
      <rect x="0" y="0" rx="5" ry="5" width="40%" height="30" />
      <rect x="0" y="50" rx="5" ry="5" width="100%" height="30" />
      {withButton && (
        <rect x="65%" y="120" rx="5" ry="5" width="35%" height="50" />
      )}
    </ContentLoader>
  );
};

TextLoader.propTypes = {
  withButton: PropTypes.bool,
};

export default memo(TextLoader);

import { useLazyQuery } from '@apollo/client';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { DOWNLOAD_FILES } from 'queries';
import { sessionTokenState } from 'states/app';
import { notificationState } from 'states/notification';
import { createAndDownloadBlobFileFromBase64 } from 'utils/DownloadFileHelper';
import { graphQLError } from 'utils/Helpers';

const useDownloadFiles = (setFileIds) => {
  const setNotification = useSetRecoilState(notificationState);
  const { accessReportId } = useParams();
  const sessionToken = useRecoilValue(sessionTokenState);

  // Load report mutation
  const [downloadFileById, { loading }] = useLazyQuery(DOWNLOAD_FILES, {
    onCompleted: (data) => {
      if (data?.result?.failedFileIds?.length === 0) {
        data.result.files.forEach((file) => {
          createAndDownloadBlobFileFromBase64(file.name, file.binaryFile);
        });
        setFileIds([]);

        setNotification({
          text: 'DownloadSuccess',
        });
      } else {
        setNotification({
          class: 'notification--alert',
          text: 'DownloadFailed',
        });
      }
    },

    onError: (error) => {
      setNotification({
        class: 'notification--alert',
        text: graphQLError(error),
      });
    },
  });

  const onDownload = useCallback(
    (fileIds) => {
      downloadFileById({
        variables: {
          sessionToken: sessionToken?.value,
          accessReportId,
          fileIds,
        },
      });
    },
    [accessReportId, downloadFileById, sessionToken]
  );

  return { onDownload, loading };
};

export default useDownloadFiles;

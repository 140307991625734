import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

import { documentTitleState } from 'states/app';

const useDocumentTitle = (title) => {
  const setDocumentTitle = useSetRecoilState(documentTitleState);
  useEffect(() => {
    setDocumentTitle(title);
  }, [setDocumentTitle, title]);
};

export default useDocumentTitle;

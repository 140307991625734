import { useCallback, useState, memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import DelayButton from 'components/DelayButton';
import Title from 'components/Title';
import { AUTHENTICATION_METHOD } from 'constants/app';
import { generateChallenge } from 'utils/Helpers';
import LoadingSpinner from 'utils/LoadingSpinner';

import { useVerifyToken, useSendToken } from './hooks';

const ID_BUTTON_DELAY = 'resendToken';

const Verify = ({ accessReportId, data, texts, refetch }) => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const [token, setToken] = useState();

  const authenticationMethod = data.result.authenticationMethod;

  const { loading: loadingSendToken, sendToken } = useSendToken(
    accessReportId,
    ID_BUTTON_DELAY
  );

  const { loading: loadingVerifyToken, onVerify } = useVerifyToken(
    accessReportId,
    refetch,
    ID_BUTTON_DELAY
  );

  useEffect(() => {
    if (authenticationMethod === AUTHENTICATION_METHOD.MOBILE_ID) {
      const tokenTmp = generateChallenge();
      setToken(tokenTmp);
      onVerify(tokenTmp);
    } else if (!state?.retry) {
      sendToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticationMethod]);

  const onChange = useCallback((newValue) => setToken(newValue), []);

  return (
    <>
      <Title title={texts.title} />

      <div className="margin-top-4">{`${t(texts.desc)}${
        authenticationMethod === AUTHENTICATION_METHOD.MOBILE_ID
          ? `. ID: ${token}`
          : ''
      }`}</div>

      {loadingVerifyToken ||
      authenticationMethod === AUTHENTICATION_METHOD.MOBILE_ID ? (
        <LoadingSpinner class="loading-page" />
      ) : (
        <>
          <div className="row margin-top-4">
            <div className="col-xs">
              <sdx-input
                type="text"
                placeholder={t('Token')}
                ref={(node) => {
                  if (node) {
                    node.changeCallback = onChange;
                    node.hitEnterCallback = () => token && onVerify(token);
                  }
                }}
              />
            </div>

            <div className="col-xs">
              <sdx-button-group layout="fill">
                <sdx-button
                  type="submit"
                  label={t('Verify')}
                  disabled={!token}
                  onClick={() => onVerify(token)}
                ></sdx-button>
              </sdx-button-group>
            </div>
          </div>

          <div className="row margin-top-1">
            <div className="col-md-6 offset-md-6">
              <DelayButton
                theme="secondary"
                idButton={ID_BUTTON_DELAY}
                label={t('ResendTokenButton')}
                loading={loadingSendToken}
                onClick={() => sendToken(token, true)}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default memo(Verify);

import { memo } from 'react';

import RobotImage from 'images/robot.png';
import PageErrorTemplate from 'pages/PageErrorTemplate';

const DownloadLocked = () => {
  return (
    <PageErrorTemplate
      img={RobotImage}
      title="AccessReportLocked"
      desc="AccessReportLockedDescription"
    />
  );
};

export default memo(DownloadLocked);

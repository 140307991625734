import { from, HttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';

import { client as clientBase } from 'configurations/apolloClient';
import {
  BACKEND_GRAPHQL_URL,
  ERRORS_SERVER_STATUS,
  ERROR_SERVER_TYPES,
} from 'constants/app';
import { errorState } from 'states/app';

const httpLink = new HttpLink({
  uri: BACKEND_GRAPHQL_URL,
});

const useApolloClient = () => {
  const [client, setClient] = useState();
  const setError = useSetRecoilState(errorState);

  // Apollo client links
  useEffect(() => {
    const errorLink = onError(({ networkError }) => {
      // Network errors
      if (
        networkError &&
        ERRORS_SERVER_STATUS.find(
          (errorId) => errorId === networkError.statusCode
        )
      ) {
        setError(ERROR_SERVER_TYPES.SERVER_ERROR);
      }
    });

    const additiveLink = from([errorLink, httpLink]);

    clientBase.setLink(additiveLink);
    setClient({ ...clientBase });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return client;
};

export default useApolloClient;

import React, { memo } from 'react';
import { Route, Routes } from 'react-router-dom';

import { useErrorServer } from 'hooks';
import { CheckAccessReportId, CheckToken } from 'pages/Checking';
import Documents from 'pages/Documents';
import { DownloadExpired } from 'pages/DownloadExpired';
import DownloadLocked from 'pages/DownloadLocked/DownloadLocked';
import PageNotFound from 'pages/PageNotFound';
import PageRedirectToDefault from 'pages/PageRedirectToDefault';
import PageServerError from 'pages/PageServerError';
import RemainingAttempts from 'pages/RemaningAttempts/RemainingAttempts';
import { SessionExpired } from 'pages/SessionExpired';
import Verify from 'pages/Verify';

const App = () => {
  useErrorServer();

  return (
    <Routes>
      <Route path="*" element={<PageNotFound />} />
      <Route exact path="/" element={<PageRedirectToDefault />} />
      <Route path="/downloadExpired" element={<DownloadExpired />} />
      <Route path="/downloadLocked" element={<DownloadLocked />} />
      <Route path="/pageNotFound" element={<PageNotFound />} />
      <Route path="/pageServerError" element={<PageServerError />} />
      <Route
        path="/sessionExpired/:accessReportId"
        element={<SessionExpired />}
      />

      <Route
        path="/remainingAttempts/:accessReportId"
        element={
          <CheckAccessReportId>
            <RemainingAttempts />
          </CheckAccessReportId>
        }
      />

      <Route
        path="/verify/:accessReportId"
        element={
          <CheckAccessReportId isFormLoader>
            <Verify />
          </CheckAccessReportId>
        }
      />

      <Route
        path="/documents/:accessReportId"
        element={
          <CheckAccessReportId>
            <CheckToken>
              <Documents />
            </CheckToken>
          </CheckAccessReportId>
        }
      />
    </Routes>
  );
};

export default memo(App);

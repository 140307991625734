import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { ERROR_SERVER_TYPES } from 'constants/app';
import { errorState } from 'states/app';

const useErrorServer = () => {
  const error = useRecoilValue(errorState);
  const navigate = useNavigate();

  useEffect(() => {
    if (error === ERROR_SERVER_TYPES.SERVER_ERROR) {
      navigate('/pageServerError');
    }
  }, [error, navigate]);
};

export default useErrorServer;

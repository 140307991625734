import { DefaultValue } from 'recoil';

export const sessionStorageEffect =
  (key) =>
  ({ setSelf, onSet }) => {
    const savedValue = sessionStorage.getItem(key);
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue, d, w) => {
      if (newValue === undefined || newValue instanceof DefaultValue) {
        sessionStorage.removeItem(key);
      } else {
        sessionStorage.setItem(
          key,
          typeof newValue === 'string' ? newValue : JSON.stringify(newValue)
        );
      }
    });
  };

import { useState, memo } from 'react';
import { useTranslation } from 'react-i18next';

import Title from 'components/Title';
import PageErrorTemplate from 'pages/PageErrorTemplate';
import { graphQLError } from 'utils/Helpers';
import { TextLoader } from 'utils/Loaders';
import LoadingSpinner from 'utils/LoadingSpinner';

import { useDownloadFiles, useGetListFiles } from './hooks';

const Documents = ({ texts }) => {
  const { t } = useTranslation();
  const [fileIds, setFileIds] = useState([]);

  const { loading: loadingFiles, error, data } = useGetListFiles();

  const { onDownload, loading: downloadingFiles } =
    useDownloadFiles(setFileIds);

  if (loadingFiles) {
    return <TextLoader />;
  }

  if (error) {
    return <PageErrorTemplate title="DataError" desc={graphQLError(error)} />;
  }

  return (
    <>
      <Title
        title={texts.title}
        iconName="icon-check-mark-circle"
        iconColor="int-green"
      />

      <div
        className="margin-top-4"
        dangerouslySetInnerHTML={{ __html: t('DownloadDescription') }}
      />

      <div className="margin-top-4">
        <sdx-input-group
          type="checkbox"
          ref={(node) => {
            if (node) {
              node.changeCallback = setFileIds;
            }
          }}
        >
          {data.result.map((file) => {
            return (
              <div key={file.id} className="margin-top-1">
                <sdx-input-item
                  name="DocumentsInputGroupFile"
                  value={file.id}
                  checked={fileIds.includes(file.id)}
                  disabled={downloadingFiles || file.disabledDownload}
                >
                  {file.name}
                </sdx-input-item>
              </div>
            );
          })}
        </sdx-input-group>
      </div>

      <div className="row margin-top-4 margin-bottom-2">
        <div className="col-md-6 offset-md-6">
          {downloadingFiles ? (
            <LoadingSpinner size="small" class="loading-button" />
          ) : (
            <sdx-button-group layout="fill">
              <sdx-button
                type="text"
                label={t('DownloadButton')}
                placeholder={t('DownloadButton')}
                disabled={fileIds.length === 0}
                onClick={() => onDownload(fileIds)}
              ></sdx-button>
            </sdx-button-group>
          )}
        </div>
      </div>
    </>
  );
};

export default memo(Documents);

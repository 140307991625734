import { useCallback, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Title from 'components/Title';

const RemainingAttempts = ({ accessReportId, texts, data }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const onNavigate = useCallback(() => {
    navigate(`/verify/${accessReportId}`, { state: { retry: true } });
  }, [accessReportId, navigate]);

  return (
    <>
      <Title
        title={texts.title}
        iconName="icon-clear-circle"
        iconColor="sc-red"
      />

      <div className="margin-top-4">{t('TokenInvalid')}</div>

      <div className="margin-top-1">
        {`${t('RemainingAttempts')}: `}
        <strong>{data.result.remainingAttempts}</strong>
      </div>

      <div className="row margin-top-4">
        <div className="col-md-6 offset-md-6">
          <sdx-button-group layout="fill">
            <sdx-button onClick={onNavigate} label={t('Retry')}></sdx-button>
          </sdx-button-group>
        </div>
      </div>
    </>
  );
};

export default memo(RemainingAttempts);

import PropTypes from 'prop-types';
import { createElement, memo } from 'react';

import PageErrorTemplate from 'pages/PageErrorTemplate';
import { graphQLError } from 'utils/Helpers';
import { FormLoader, TextLoader } from 'utils/Loaders';

import { useCheckAccessReport } from './hooks';

const CheckAccessReportId = ({ children, isFormLoader = false }) => {
  const { loading, error, ...props } = useCheckAccessReport();

  if (loading) {
    return isFormLoader ? <FormLoader /> : <TextLoader />;
  }

  if (error) {
    return <PageErrorTemplate title="DataError" desc={graphQLError(error)} />;
  }

  return createElement(children.type, {
    ...{
      ...children.props,
      ...props,
    },
  });
};

CheckAccessReportId.propTypes = {
  isFormLoader: PropTypes.bool,
};

export default memo(CheckAccessReportId);

import { useMemo } from 'react';

import Notification from 'utils/Notification';

const PageTemplate = ({ children }) => {
  const notification = useMemo(() => <Notification />, []);

  return (
    <>
      {notification}
      <div className="container min-height margin-top-4 margin-bottom-4">
        {children}
      </div>
    </>
  );
};

export default PageTemplate;

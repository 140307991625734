import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useDocumentTitle } from 'hooks';

const Title = ({ title, iconName, iconColor }) => {
  const { t } = useTranslation();
  useDocumentTitle(title);

  return (
    <h2>
      {t(title)}

      <sdx-icon
        icon-name={iconName}
        class={`${iconColor} margin-left-4 hydrated`}
        size="3"
      ></sdx-icon>
    </h2>
  );
};

export default memo(Title);

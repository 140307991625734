import { memo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { documentTitleState } from 'states/app';

const HelmetTitle = () => {
  const { t } = useTranslation();
  const title = useRecoilValue(documentTitleState);

  return (
    <Helmet>
      <title>{`${t(title)} | Filebox 2.0 | Swisscom`}</title>
    </Helmet>
  );
};

export default memo(HelmetTitle);

import { FORMAT_TYPES, MIME_TYPES } from 'constants/app';

function getMimeType(format) {
  switch (format.toLowerCase()) {
    case FORMAT_TYPES.CSV:
      return MIME_TYPES.CSV;
    case FORMAT_TYPES.PDF:
      return MIME_TYPES.PDF;
    case FORMAT_TYPES.PPT:
    case FORMAT_TYPES.PPTX:
      return MIME_TYPES.PPT;
    case FORMAT_TYPES.XLS:
    case FORMAT_TYPES.XLSX:
      return MIME_TYPES.XLS;
    case FORMAT_TYPES.ZIP:
      return MIME_TYPES.ZIP;
    default:
      return MIME_TYPES.TXT;
  }
}

function getFileExtension(format) {
  return Object.values(FORMAT_TYPES).includes(format.toLowerCase())
    ? format.toLowerCase()
    : '';
}

function getMimeTypeAndFileExtension(format) {
  return [getMimeType(format), getFileExtension(format)];
}

function hexToByteArray(hexStr) {
  hexStr = hexStr.slice(2);
  var buf = new ArrayBuffer(hexStr.length / 2);
  var byteBuf = new Uint8Array(buf);
  for (let i = 0; i < hexStr.length; i += 2) {
    byteBuf[i / 2] = parseInt(hexStr.slice(i, i + 2), 16);
  }
  return byteBuf;
}

export function downloadBlobFile(blob, filename, extension) {
  // IE handling
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveBlob(blob, filename + '.' + extension);
  } else {
    var a = window.document.createElement('a');
    a.href = window.URL.createObjectURL(blob);
    a.download = filename + '.' + extension;

    // Append anchor to body, click and remove anchor again
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}

export function createAndDownloadBlobFile(body, filename, format) {
  const [mimeType, extension] = getMimeTypeAndFileExtension(format);
  var blob = new Blob([hexToByteArray(body)], { type: mimeType });

  downloadBlobFile(blob, filename, extension);
}

export async function createAndDownloadBlobFileFromBase64(filename, body) {
  const format = filename.split('.')[1];
  const name = filename.split('.')[0];

  const [mimeType, extension] = getMimeTypeAndFileExtension(format);
  const blob = await fetch(`data:${mimeType};base64,${body}`)
    .then((result) => result.blob())
    .catch(() => false);

  downloadBlobFile(blob, name, extension);
}

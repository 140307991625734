import { useLazyQuery } from '@apollo/client';
import Moment from 'moment';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useResetRecoilState, useSetRecoilState } from 'recoil';

import { STATUS_TYPE } from 'constants/app';
import { CHECK_TWO_FACTOR_AUTH } from 'queries';
import { delayButtonState, sessionTokenState } from 'states/app';
import { notificationState } from 'states/notification';
import { errorWithBackend } from 'utils/Helpers';

const useVerifyToken = (accessReportId, refetch, idButton) => {
  const navigate = useNavigate();
  const resetDelayButton = useResetRecoilState(delayButtonState(idButton));
  const setNotification = useSetRecoilState(notificationState);
  const setSessionToken = useSetRecoilState(sessionTokenState);

  const [query, { loading }] = useLazyQuery(CHECK_TWO_FACTOR_AUTH, {
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      resetDelayButton();

      switch (data?.result?.statusType) {
        case STATUS_TYPE.CHANGED_TO_SMS:
          navigate(`/verify/${accessReportId}`);
          break;

        case STATUS_TYPE.SUCCESS:
          setSessionToken({
            value: data.result.sessionToken,
            datetime: Moment().format(),
          });
          navigate(`/documents/${accessReportId}`);
          break;

        default:
          refetch();
          navigate(`/remainingAttempts/${accessReportId}`);
          break;
      }
    },
    onError(error) {
      errorWithBackend(
        error,
        navigate,
        accessReportId,
        setNotification,
        refetch
      );
    },
  });

  const onVerify = useCallback(
    (token) =>
      query({
        variables: {
          accessReportId,
          token,
        },
      }),
    [accessReportId, query]
  );

  return { loading, onVerify };
};

export default useVerifyToken;

import _ from 'lodash';

import { GRAPHQL_ERRORS } from 'constants/app';

export const isUsingFirefox = () => navigator.userAgent.indexOf('Firefox') > 0;

export const displayStringWithLineReturn = (value) => {
  return value
    ?.replace(/(\n)$/gm, '')
    .split('\n')
    .map((i, key) => {
      return i === '' ? <br key={key} /> : <div key={key}>{i}</div>;
    });
};

export const getCookies = () => {
  return document.cookie
    .split('; ')
    .map((item) => item.split('='))
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
};

export const backendHeaders = () => {
  const cookies = getCookies();

  return {
    headers: {
      ...(cookies['XSRF-TOKEN'] != null && {
        'x-xsrf-token': cookies['XSRF-TOKEN'],
      }),
    },
  };
};

export const generateChallenge = () => {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'.split('');

  return _.map(new Array(5), () => _.sample(characters)).join('');
};

export const graphQLError = (error) => {
  return (
    error.graphQLErrors?.[0]?.extensions?.customError ||
    GRAPHQL_ERRORS.UNKNOWN_ERROR
  );
};

export const errorWithBackend = (
  error,
  navigate,
  accessReportId,
  setNotification,
  refetch
) => {
  switch (graphQLError(error)) {
    case GRAPHQL_ERRORS.ACCESS_REPORT_EXPIRED:
      navigate('/downloadExpired');
      break;

    case GRAPHQL_ERRORS.ACCESS_REPORT_LOCKED:
      navigate('/downloadLocked');
      break;

    case GRAPHQL_ERRORS.ACCESS_REPORT_NOT_FOUND:
    case GRAPHQL_ERRORS.AUTHENTICATION_METHOD_INVALID:
    case GRAPHQL_ERRORS.UNKNOWN_ERROR:
      navigate('/pageNotFound');
      break;

    case GRAPHQL_ERRORS.EMAIL_ERROR:
    case GRAPHQL_ERRORS.SMS_ERROR:
      setNotification({
        class: 'notification--alert',
        text: graphQLError(error),
      });
      break;

    // Notification needed as there are special Mobile ID errors
    case GRAPHQL_ERRORS.MOBILE_ID_ERROR:
    case GRAPHQL_ERRORS.MOBILE_ID_GEOFENCING_FAILED:
    case GRAPHQL_ERRORS.MOBILE_ID_NOT_SUPPORTED:
      setNotification({
        class: 'notification--alert',
        text: graphQLError(error),
      });

      refetch();
      navigate(`/remainingAttempts/${accessReportId}`);
      break;

    case GRAPHQL_ERRORS.MOBILE_ID_AUTHENTICATION_FAILED:
    case GRAPHQL_ERRORS.TOKEN_INVALID:
      refetch();
      navigate(`/remainingAttempts/${accessReportId}`);
      break;

    default:
      setNotification({
        class: 'notification--alert',
        text: 'UnknownError',
      });
      break;
  }
};

import { memo } from 'react';
import { useTranslation } from 'react-i18next';

const LoadingSpinner = (props) => {
  const { t } = useTranslation();

  return (
    <sdx-loading-spinner
      class={props.class || 'loading-center'}
      size={props.size || 'large'}
      sr-hint={t('LoadingPleaseWait')}
    />
  );
};

export default memo(LoadingSpinner);

import { atomFamily, atom } from 'recoil';
import { v4 as uuidv4 } from 'uuid';

import { SESSION_STORAGE_KEYS } from 'constants/app';
import { sessionStorageEffect } from 'service/storage';

export const documentTitleState = atom({
  key: uuidv4(),
  default: '',
});

export const delayButtonState = atomFamily({
  key: uuidv4(),
  effects: (key) => [
    sessionStorageEffect(`${SESSION_STORAGE_KEYS.DELAY_BUTTON}_${key}`),
  ],
  default: undefined,
});

export const sessionTokenState = atom({
  key: uuidv4(),
  effects: [sessionStorageEffect(SESSION_STORAGE_KEYS.SESSION_TOKEN)],
  default: undefined,
});

export const errorState = atom({
  key: uuidv4(),
  default: undefined,
});

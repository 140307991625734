import { memo } from 'react';

import PageNotAuthorizedImage from 'images/page-server-internal-error.svg';

import PageErrorTemplate from './PageErrorTemplate';

const PageServerError = () => {
  return (
    <PageErrorTemplate
      img={PageNotAuthorizedImage}
      title="InternalServerError"
      desc="InternalServerErrorDescription"
    />
  );
};

export default memo(PageServerError);

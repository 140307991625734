import { memo } from 'react';

import RobotImage from 'images/robot.png';
import PageErrorTemplate from 'pages/PageErrorTemplate';

const DownloadExpired = () => {
  return (
    <PageErrorTemplate
      img={RobotImage}
      title="AccessReportExpired"
      desc="AccessReportExpiredDescription"
    />
  );
};

export default memo(DownloadExpired);

import { useLazyQuery } from '@apollo/client';
import Moment from 'moment';
import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

import { SEND_TOKEN } from 'queries';
import { delayButtonState } from 'states/app';
import { notificationState } from 'states/notification';
import { graphQLError } from 'utils/Helpers';

const useSendToken = (accessReportId, idButton) => {
  const setNotification = useSetRecoilState(notificationState);
  const setDelayButton = useSetRecoilState(delayButtonState(idButton));

  const [query, { loading }] = useLazyQuery(SEND_TOKEN, {
    nextFetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      if (!data.result) {
        setNotification({
          class: 'notification--alert',
          text: 'UnknownError',
        });
      }
    },
    onError(error) {
      setNotification({
        class: 'notification--alert',
        text: graphQLError(error),
      });
    },
  });

  const sendToken = useCallback(
    (token, resend) =>
      query({
        variables: {
          accessReportId,
          token,
        },
      }).then((result) => {
        if (!result?.errors && resend) {
          setDelayButton({ moment: Moment().format() });
          setNotification({
            text: 'ResendTokenSent',
          });
        }
      }),
    [accessReportId, query, setDelayButton, setNotification]
  );

  return { loading, sendToken };
};

export default useSendToken;

import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useDocumentTitle } from 'hooks';
import PageNotFoundImage from 'images/page-not-found.svg';

const PageErrorTemplate = ({ img: imgBase, title, desc, btnText, link }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useDocumentTitle(title);

  const img = imgBase || PageNotFoundImage;

  return (
    <>
      <div className="page-info-image margin-bottom-3">
        <img src={img} className="page-info-image-content" alt={t(title)} />
      </div>

      <div>
        <h2>{t(title)}</h2>
        {desc && t(desc)}
      </div>

      {btnText && link && (
        <div className="row margin-top-4 margin-bottom-2">
          <div className="col-md-6 offset-md-6">
            <sdx-button-group layout="fill">
              <sdx-button
                type="submit"
                label={t(btnText)}
                onClick={() => navigate(link)}
              ></sdx-button>
            </sdx-button-group>
          </div>
        </div>
      )}
    </>
  );
};

export default memo(PageErrorTemplate);

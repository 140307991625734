import {
  from,
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';

import { BACKEND_GRAPHQL_URL } from 'constants/app';
import { backendHeaders } from 'utils/Helpers';

export const httpLink = new HttpLink({
  uri: BACKEND_GRAPHQL_URL,
});

const checkValidityNevis = () => {
  fetch(document.location)
    .then((response) => {
      if (
        response.headers.has('isiwebauthstate') &&
        response.headers.get('isiwebauthstate').trim() !== 'valid'
      )
        window.location.assign(document.location.origin);
    })
    .catch(() => false);
};

export const backendHeadersLink = new ApolloLink((operation, forward) => {
  operation.setContext({
    ...backendHeaders(),
  });

  checkValidityNevis();

  return forward(operation);
});

const additiveLink = from([backendHeadersLink, httpLink]);

// Apollo configuration
export const client = new ApolloClient({
  cache: new InMemoryCache({
    addTypename: true,
    possibleTypes: {},
  }),
  link: additiveLink,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
      refetchWritePolicy: 'overwrite',
    },
  },
});

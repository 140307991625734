import React from 'react';
import * as ReactDOMClient from 'react-dom/client';

import Main from './Main';
import * as serviceWorker from './serviceWorker';

import '@swisscom/sdx/dist/css/sdx.min.css';
import './styles/App.scss';
import './styles/InfoPages.scss';

// Render root path
const root = ReactDOMClient.createRoot(document.getElementById('root'));
root.render(<Main />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import { memo } from 'react';

import { changeLanguage, language } from 'i18n';

const Header = () => {
  window.addEventListener('languageChanged', (event) => {
    changeLanguage(event.detail);
  });

  return (
    <scs-header
      current-language={language()}
      customer-segment="res"
      show-search="false"
      show-secondary-links="false"
      show-tertiary-links="false"
    >
      <scs-header-l0 />
      <scs-header-l1
        logo-href=""
        show-login="false"
        show-notifications="false"
      />
    </scs-header>
  );
};

export default memo(Header);

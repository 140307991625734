import Moment from 'moment';
import { createElement, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { TIME_BEFORE_TOKEN_EXPIRE } from 'constants/app';
import { sessionTokenState } from 'states/app';

const checkTimeIsOver = (datetime) => {
  const datetimeLastToken = Moment(datetime).add(TIME_BEFORE_TOKEN_EXPIRE, 'm');
  return datetimeLastToken.diff(Moment(), 'seconds') <= 0;
};

const CheckToken = ({ accessReportId, children, ...props }) => {
  const sessionToken = useRecoilValue(sessionTokenState);
  const navigate = useNavigate();

  const checkAndResetToken = useCallback(
    (datetime) => {
      if (!datetime) {
        navigate(`/verify/${accessReportId}`);
      }

      if (checkTimeIsOver(datetime)) {
        navigate(`/sessionExpired/${accessReportId}`);
      }
    },
    [accessReportId, navigate]
  );

  useEffect(() => {
    checkAndResetToken(sessionToken?.datetime);
    const reloadTimeButton = setInterval(
      () => checkAndResetToken(sessionToken?.datetime),
      5000
    );
    return () => reloadTimeButton && clearInterval(reloadTimeButton);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionToken]);

  return sessionToken
    ? createElement(children.type, {
        ...{
          ...children.props,
          accessReportId,
          ...props,
        },
      })
    : false;
};

export default CheckToken;

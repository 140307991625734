import { memo } from 'react';

import PageErrorTemplate from './PageErrorTemplate';

const PageNotFound = () => {
  return (
    <PageErrorTemplate title="PageNotFound" desc="PageNotFoundDescription" />
  );
};

export default memo(PageNotFound);

import { memo, useCallback, useEffect } from 'react';

const CLASS_NAME = 'accessibility-mode-on';

const AccessibilityMode = () => {
  const onUserInteraction = useCallback((e) => {
    if (
      e.type === 'pointerup' &&
      document.body.classList.length &&
      document.body.classList.contains(CLASS_NAME)
    ) {
      // clicked
      document.body.classList.remove(CLASS_NAME);
    }

    if (
      e.type === 'keyup' &&
      e.key === 'Tab' &&
      !document.body.classList.contains(CLASS_NAME)
    ) {
      // tabbed
      document.body.classList.add(CLASS_NAME);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('pointerup', onUserInteraction);
    window.addEventListener('keyup', onUserInteraction);
    return () => {
      window.removeEventListener('pointerup', onUserInteraction);
      window.removeEventListener('keyup', onUserInteraction);
    };
  }, [onUserInteraction]);

  return false;
};

export default memo(AccessibilityMode);

import { memo } from 'react';
import ContentLoader from 'react-content-loader';

const FormLoader = () => {
  return (
    <ContentLoader width="100%" viewBox={`0 0 800 270`}>
      <rect x="0" y="0" rx="5" ry="5" width="40%" height="30" />
      <rect x="0" y="50" rx="5" ry="5" width="100%" height="30" />
      <rect x="0" y="120" rx="5" ry="5" width="63%" height="50" />
      <rect x="65%" y="120" rx="5" ry="5" width="35%" height="50" />
      <rect x="65%" y="185" rx="5" ry="5" width="35%" height="50" />
    </ContentLoader>
  );
};

export default memo(FormLoader);

import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { GET_LIST_OF_FILES } from 'queries';
import { sessionTokenState } from 'states/app';

const useGetListFiles = () => {
  const { accessReportId } = useParams();
  const sessionToken = useRecoilValue(sessionTokenState);

  const { loading, error, data } = useQuery(GET_LIST_OF_FILES, {
    variables: { accessReportId, sessionToken: sessionToken?.value },
  });

  return { data, error, loading };
};

export default useGetListFiles;

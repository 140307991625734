import { ErrorBoundary as ErrorBoundaryBase } from 'react-error-boundary';
import { useNavigate } from 'react-router-dom';

import PageErrorTemplate from 'pages/PageErrorTemplate';

const ErrorFallback = () => {
  return (
    <div
      className="container min-height margin-top-4 margin-bottom-4"
      role="alert"
    >
      <PageErrorTemplate title="DataError" desc="UnknownError" />
    </div>
  );
};

const ErrorBoundaries = ({ navigate, children }) => {
  return (
    <ErrorBoundaryBase
      FallbackComponent={ErrorFallback}
      onReset={() => {
        navigate?.('/');
      }}
    >
      {children}
    </ErrorBoundaryBase>
  );
};

export const ErrorBoundariesWithNavigate = (props) => {
  const navigate = useNavigate();
  return <ErrorBoundaries navigate={navigate} {...props} />;
};

export default ErrorBoundaries;

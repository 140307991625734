import { memo } from 'react';
import { useParams } from 'react-router-dom';

import RobotImage from 'images/robot.png';
import PageErrorTemplate from 'pages/PageErrorTemplate';

const SessionExpired = () => {
  const { accessReportId } = useParams();

  return (
    <PageErrorTemplate
      img={RobotImage}
      title="SessionExpired"
      desc="SessionExpiredDescription"
      btnText="SessionExpiredRetry"
      link={`/verify/${accessReportId}`}
    />
  );
};

export default memo(SessionExpired);

import { ApolloProvider } from '@apollo/client';
import '@swisscom/sdx/dist/css/webcomponents.css';
import { defineCustomElements } from '@swisscom/sdx/dist/js/webcomponents/loader';
import { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router } from 'react-router-dom';

import { useApolloClient } from 'hooks';
import PageTemplate from 'pages/PageTemplate';
import Routes from 'routes/App';
import { ErrorBoundariesWithNavigate } from 'utils/ErrorBoundaries';
import HelmetTitle from 'utils/HelmetTitle';

const App = () => {
  // SDX
  useEffect(() => void defineCustomElements(), []);

  const client = useApolloClient();

  return (
    <main>
      <HelmetProvider>
        <HelmetTitle />
        {client && (
          <ApolloProvider client={client}>
            <Router>
              <ErrorBoundariesWithNavigate>
                <PageTemplate>
                  <Routes />
                </PageTemplate>
              </ErrorBoundariesWithNavigate>
            </Router>
          </ApolloProvider>
        )}
      </HelmetProvider>
    </main>
  );
};
export default App;
